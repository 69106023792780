import React, { useState } from "react";
import mobile1 from "../assets/img/mob-img-1.svg";
import mobile2 from "../assets/img/banner2.svg";
import Header from "./common/Header";
import Footer from "./Footer";


const PrivacyPolicy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="bg-img flex items-center justify-center h-[800px] min-h-[800px]  px-2">
        <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <div className="flex relative h-full justify-center 2xl:w-[80%] xl:w-[100%] w-full  items-center">
          <img
            src={mobile1}
            alt=""
            className="hidden md:block  lg:mt-32 absolute  2xl:h-[650px] h-[450px] xl:h-[600px] lg:h-[550px] -bottom-[132px] left-0 sm:mt-[1px] "
          />
          <div className="text-center flex items-center justify-center  font-medium flex-col">
            <h1 className=" lg:text-[40px] md:text-[30px] sm:text-[25px] font-semibold text-[25px] pb-3">
              Your Tool to Seamlessly Host, Manage
              <br />
              <span className="text-white">Open Houses</span>
            </h1>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
              className="lg:text-[18px] flex items-center justify-center whitespace-nowrap hover:bg-white/90 transition-all mt-4 text-base font-semibold bg-white lg:py-[10px]  min-w-[274px] tracking-wider py-[10px]  rounded-[5px]"
            >
              REGISTER TODAY!
            </button>
          </div>
          <img
            src={mobile2}
            alt=""
            className=" hidden md:block absolute -bottom-[132px] 2xl:h-[650px] h-[450px] lg:h-[550px] xl:h-[600px] right-0 lg:mt-32 sm:mt-[1px] "
          />
        </div>
      </div>
      <div className="w-11/12 md:container mx-auto my-4 md:my-8 md:pt-20 pt-3">
        <h1 className="text-lg md:text-xl lg:text-3xl font-bold mb-2 md:mb-6">Privacy Policy for ScoutHeim Open House Application</h1>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">1. Information We Collect:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim collects personal information during the account registration process, including but not limited to your name, contact information, and other details relevant to the use of the application. We may also collect non-personal information such as device information and usage statistics.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">2. Use of Information:</h2>
          <p className='text-sm md:text-base'>
            The information collected by ScoutHeim is used to provide and improve our services. This includes facilitating open house events, personalizing user experience, and communicating with users about property listings and application updates.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">3. Data Security:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim employs industry-standard security measures to protect the confidentiality and integrity of your personal information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">4. Third-Party Services:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim may utilize third-party services and tools to enhance our functionality. These services may have their own privacy policies, and your use of ScoutHeim implies acceptance of those policies.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">5. Cookies:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim may use cookies and similar technologies to enhance user experience, personalize content, and analyze application usage. You can manage cookie preferences through your browser settings.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">6. Information Sharing:</h2>
          <p className='text-sm md:text-base'>
            We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share information with trusted third parties who assist us in operating the application or servicing you, provided they agree to keep this information confidential.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">7. Children's Privacy:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe we may have collected information about your child, please contact us immediately.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">8. Changes to Privacy Policy:</h2>
          <p className='text-sm md:text-base'>
            ScoutHeim reserves the right to update this Privacy Policy at any time. We will notify users of any changes by posting the updated policy on the application. Your continued use of ScoutHeim after the posting of changes constitutes acceptance of the modified Privacy Policy.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">9. Contact Information:</h2>
          <p className='text-sm md:text-base'>
            For questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:info@scoutheim.com" className="text-blue-500">info@scoutheim.com</a>.
          </p>
        </section>

        <section className="mb-4 md:mb-6 lg:mb-8">
          <p className='text-sm md:text-base'>
            By using the ScoutHeim Open House Application, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. If you do not agree to this policy, please do not use the application.
          </p>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy