import logo from "./logo.svg";
import "./App.css";
import "./assets/scss/index.scss";
import Home from "./component/Home";
import { Toaster, toast } from "sonner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermandConditions from "./component/TermandConditions";
import Faqs from "./component/Faqs";
import ContactUs from "./component/ContactUs";


function App() {
  return (
    <>
      <Toaster
        position="top-center"
        expand={true}
        visibleToasts={1}
        toastOptions={{
          unstyled: false,
          duration: 4000,
        }}
        richColors={true}
        closeButton={true}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-conditions" element={<TermandConditions />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
