import React, { useState } from "react";
import mobile1 from "../assets/img/mob-img-1.svg";
import mobile2 from "../assets/img/banner2.svg";
import Header from "./common/Header";
import Footer from "./Footer";

const Faqs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className="bg-img flex items-center justify-center h-[800px] min-h-[800px]  px-2">
                <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                <div className="flex relative h-full justify-center 2xl:w-[80%] xl:w-[100%] w-full  items-center">
                    <img
                        src={mobile1}
                        alt=""
                        className="hidden md:block  lg:mt-32 absolute  2xl:h-[650px] h-[450px] xl:h-[600px] lg:h-[550px] -bottom-[132px] left-0 sm:mt-[1px] "
                    />
                    <div className="text-center flex items-center justify-center  font-medium flex-col">
                        <h1 className=" lg:text-[40px] md:text-[30px] sm:text-[25px] font-semibold text-[25px] pb-3">
                            Your Tool to Seamlessly Host, Manage
                            <br />
                            <span className="text-white">Open Houses</span>
                        </h1>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setIsModalOpen(true);
                            }}
                            className="lg:text-[18px] flex items-center justify-center whitespace-nowrap hover:bg-white/90 transition-all mt-4 text-base font-semibold bg-white lg:py-[10px]  min-w-[274px] tracking-wider py-[10px]  rounded-[5px]"
                        >
                            REGISTER TODAY!
                        </button>
                    </div>
                    <img
                        src={mobile2}
                        alt=""
                        className=" hidden md:block absolute -bottom-[132px] 2xl:h-[650px] h-[450px] lg:h-[550px] xl:h-[600px] right-0 lg:mt-32 sm:mt-[1px] "
                    />
                </div>
            </div>
            <div className="w-11/12 md:container mx-auto my-4 md:my-8 md:pt-20 pt-3">
                <h1 className="text-lg md:text-xl lg:text-3xl font-bold mb-2 md:mb-6">
                    FAQs for ScoutHeim Open House Application
                </h1>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q1: What is Scout Heim, and how can it benefit me as a real estate
                        agent?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: Scout Heim is a platform designed for real estate agents to
                        effortlessly list properties and host open houses. It enhances
                        visibility and simplifies the process of connecting with potential
                        buyers. To get started, create an account, list your properties, and
                        explore the open house hosting features.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q2: How do I create a Scout Heim account?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: To create an account on Scout Heim, download the app from the App
                        Store or Google Play. Follow the on-screen instructions to sign up,
                        using your email address or social media accounts for a quick and
                        easy registration process.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q3: Can I manage multiple properties with a single Scout Heim
                        account?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: Yes, Scout Heim allows you to efficiently manage multiple
                        properties with a single account. After logging in, you can easily
                        switch between different properties and update their information as
                        needed.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q4: How do I list a property on Scout Heim?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: Listing a property on Scout Heim is simple. Log in, go to the
                        "List Property" section, and enter details such as property type,
                        location, features, and images. Follow the prompts to complete the
                        listing process.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q5: What are the benefits of hosting an open house on Scout Heim?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: Hosting an open house on Scout Heim provides a platform to
                        showcase your properties to a wide audience of potential buyers. It
                        enhances visibility and simplifies the scheduling and communication
                        process with interested parties.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q6: How do I schedule an open house on Scout Heim?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: To schedule an open house, navigate to the "Host Open House"
                        section in the app. Select the property, choose a date and time, and
                        provide additional details. The app will display the open house
                        information for potential attendees.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q7: Can I communicate with potential buyers through Scout Heim?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: Yes, Scout Heim includes communication features for interaction
                        with potential buyers. Respond to inquiries, provide additional
                        property information, and coordinate details for open house events.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q8: Is Scout Heim available in multiple languages?
                    </h2>
                    <div className='text-sm md:text-base'>A: Currently, Scout Heim is available in English.</div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q9: How do I receive notifications for property inquiries and open
                        house attendance on Scout Heim?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: You will receive notifications through the app for property
                        inquiries and open house attendance. Ensure your notification
                        settings are enabled to stay informed about potential buyer interest
                        and event participation.
                    </div>
                </section>

                <section className="mb-4 md:mb-6 lg:mb-8">
                    <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">
                        Q10: Where can I find support for any issues or questions?
                    </h2>
                    <div className='text-sm md:text-base'>
                        A: If you have any questions or encounter issues, our support team
                        is here to help! You can reach out to us by emailing{" "}
                        <a
                            href="mailto:info@scoutheim.com"
                            target="_blank"
                            className="text-blue-500"
                        >
                            info@scoutheim.com
                        </a>
                        .
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Faqs