import React, { useState } from "react";
import mobile1 from "../assets/img/mob-img-1.svg";
import mobile2 from "../assets/img/banner2.svg";
import Header from "./common/Header";
import Footer from "./Footer";

const TermandConditions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (<>
    <div className="bg-img flex items-center justify-center h-[800px] min-h-[800px]  px-2">
        <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <div className="flex relative h-full justify-center 2xl:w-[80%] xl:w-[100%] w-full  items-center">
          <img
            src={mobile1}
            alt=""
            className="hidden md:block  lg:mt-32 absolute  2xl:h-[650px] h-[450px] xl:h-[600px] lg:h-[550px] -bottom-[132px] left-0 sm:mt-[1px] "
          />
          <div className="text-center flex items-center justify-center  font-medium flex-col">
            <h1 className=" lg:text-[40px] md:text-[30px] sm:text-[25px] font-semibold text-[25px] pb-3">
              Your Tool to Seamlessly Host, Manage
              <br />
              <span className="text-white">Open Houses</span>
            </h1>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
              className="lg:text-[18px] flex items-center justify-center whitespace-nowrap hover:bg-white/90 transition-all mt-4 text-base font-semibold bg-white lg:py-[10px]  min-w-[274px] tracking-wider py-[10px]  rounded-[5px]"
            >
              REGISTER TODAY!
            </button>
          </div>
          <img
            src={mobile2}
            alt=""
            className=" hidden md:block absolute -bottom-[132px] 2xl:h-[650px] h-[450px] lg:h-[550px] xl:h-[600px] right-0 lg:mt-32 sm:mt-[1px] "
          />
        </div>
      </div>
    <div className="w-11/12 md:container mx-auto my-4 md:my-8 md:pt-20 pt-3">
      <h1 className="text-lg md:text-xl lg:text-3xl font-bold mb-2 md:mb-6">Terms and Conditions for ScoutHeim Open House Application </h1>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">1. Acceptance of Terms:</h2>
        <p className='text-sm md:text-base'>
          By accessing or using the ScoutHeim Open House Application ("the Application"), you agree to comply with and
          be bound by these Terms and Conditions.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">2. Eligibility:</h2>
        <p className='text-sm md:text-base'>You must be at least 18 years old to use the ScoutHeim Application. By using the Application, you represent and warrant that you are at least 18 years old.</p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">3. Account Registration:</h2>
        <p className='text-sm md:text-base'>
          To access certain features of ScoutHeim, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">4. Open House Events:</h2>
        <p className='text-sm md:text-base'>
          ScoutHeim facilitates open house events for properties available for rent and sale. The accuracy of property information, including pricing and availability, is the responsibility of the property owner or agent.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">5. User Conduct:</h2>
        <p className='text-sm md:text-base'>
          You agree to use ScoutHeim in accordance with all applicable laws and regulations. You shall not engage in any conduct that, in our sole discretion, restricts or inhibits any other user from using or enjoying the Application.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">6. Privacy:</h2>
        <p className='text-sm md:text-base'>
          Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information within ScoutHeim. By using the Application, you consent to the collection and use of your information as described in the Privacy Policy.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">7. Intellectual Property:</h2>
        <p className='text-sm md:text-base'>
          All content on ScoutHeim, including but not limited to text, graphics, logos, and images, is the property of the Application or its licensors and is protected by copyright, trademark, and other intellectual property laws.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">8. Liability:</h2>
        <p className='text-sm md:text-base'>
          ScoutHeim is provided "as is" and "as available." We do not guarantee the accuracy, completeness, or reliability of any content available through the Application. In no event shall ScoutHeim be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Application.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">9. Termination:</h2>
        <p className='text-sm md:text-base'>
          We reserve the right to terminate or suspend your account and access to ScoutHeim at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the Application or to us.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">10. Changes to Terms:</h2>
        <p className='text-sm md:text-base'>
          We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting. Your continued use of ScoutHeim after the posting of changes constitutes your acceptance of such changes.
        </p>
      </section>

      <section className="mb-4 md:mb-6 lg:mb-8">
        <h2 className="text-base md:text-lg lg:text-xl font-semibold mb-1 md:mb-2 lg:mb-4">11. Governing Law:</h2>
        <p className='text-sm md:text-base'>These Terms and Conditions are governed by and construed in accordance with the laws of the United States.</p>
      </section>
    </div>
    <Footer />
  </>
  )
}

export default TermandConditions