import React, { useState } from "react";
import mobile1 from "../assets/img/mob-img-1.svg";
import mobile2 from "../assets/img/banner2.svg";
import Header from "./common/Header";
import Footer from "./Footer";

const ContactUs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className="bg-img flex items-center justify-center h-[800px] min-h-[800px]  px-2">
                <Header isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                <div className="flex relative h-full justify-center 2xl:w-[80%] xl:w-[100%] w-full  items-center">
                    <img
                        src={mobile1}
                        alt=""
                        className="hidden md:block  lg:mt-32 absolute  2xl:h-[650px] h-[450px] xl:h-[600px] lg:h-[550px] -bottom-[132px] left-0 sm:mt-[1px] "
                    />
                    <div className="text-center flex items-center justify-center  font-medium flex-col">
                        <h1 className=" lg:text-[40px] md:text-[30px] sm:text-[25px] font-semibold text-[25px] pb-3">
                            Your Tool to Seamlessly Host, Manage
                            <br />
                            <span className="text-white">Open Houses</span>
                        </h1>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setIsModalOpen(true);
                            }}
                            className="lg:text-[18px] flex items-center justify-center whitespace-nowrap hover:bg-white/90 transition-all mt-4 text-base font-semibold bg-white lg:py-[10px]  min-w-[274px] tracking-wider py-[10px]  rounded-[5px]"
                        >
                            REGISTER TODAY!
                        </button>
                    </div>
                    <img
                        src={mobile2}
                        alt=""
                        className=" hidden md:block absolute -bottom-[132px] 2xl:h-[650px] h-[450px] lg:h-[550px] xl:h-[600px] right-0 lg:mt-32 sm:mt-[1px] "
                    />
                </div>
            </div>
            <div className="w-11/12 md:container mx-auto my-4 md:my-8 md:pt-20 pt-3">
                <h1 className="text-lg md:text-xl lg:text-3xl font-bold mb-2 md:mb-6">Contact Info for ScoutHeim Open House Application </h1>

                <section className="mb-8">
                    <section className="mb-8">
                        {/* <h2 className="text-xl font-semibold mb-4">Contact Us</h2> */}
                        <p>
                            If you have any questions, concerns, or feedback regarding the ScoutHeim Open House Application, we encourage you to reach out to us. Our team is here to assist you.
                        </p>
                        <p>
                            Feel free to contact us via email at <a href="mailto:info@scoutheim.com" className="text-blue-500">info@scoutheim.com</a>.
                        </p>

                        {/* Add a contact form or any other relevant contact information here */}
                    </section>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default ContactUs