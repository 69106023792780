import "react-international-phone/style.css";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "sonner";
import { Link, useLocation } from "react-router-dom";

const Header = ({ isModalOpen, setIsModalOpen }) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const windowWidth = window.innerWidth > 1024;

  useEffect(() => {
    const handleScroll = () => {
      if (!isModalOpen) {
        const navbar = document.querySelector(".navbar");
        const mobileMenu = document.querySelector(".mobile-menu");

        if (window.scrollY > 100) {
          navbar.classList.add("sticky-navbar");
          mobileMenu.style.top = "40px";
        } else {
          navbar.classList.remove("sticky-navbar");
          mobileMenu.style.top = "40px";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const history = useLocation();


  useEffect(() => {
    const hash = history.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [history.hash]);

  return (
    <>
      <div className="pt-8 navbar centered-axis-xy lg:w-full w-11/12 top-14 z-20">
        <nav className="  bg-white relative px-2 container mx-auto  flex items-center h-[88px] border-gray-200">
          <div className=" flex  items-center w-full justify-between lg:p-4  p-2  sm:p-0">
            <a
              href="#"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img
                src={logo}
                className="md:h-auto h-12 max-w-[85%] "
                alt="Flowbite Logo"
              />
            </a>
            <div className="flex items-center justify-center lg:hidden  gap-x-">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
                className=" text-white  transition-all  bg-primary md:text-base  hover:bg-primary/85 border-none rounded-[5px] font-monaRegular px-4  py-2  font-medium sm:text-[12px] text-[10px]  tracking-wider whitespace-nowrap flex-center"
              >
                Become an Agent
              </a>
              <button
                onClick={() => setMobileMenu(!mobileMenu)}
                type="button"
                className="inline-flex z-40 items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none "
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>

            <div
              className={`hidden  w-full lg:flex items-center justify-center md:w-auto`}
              id="navbar-default"
            >
              <ul className="font-medium flex items-center flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white ">
                <li className="xl:px-3 lg:px-0">
                  <Link
                    to={"/"}
                    className="block py-2 px-3 text-secondary rounded hover:text-primary xl:text-[18px] lg:text-base whitespace-nowrap transition-all"
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li className="xl:px-3 lg:px-0">
                  <Link
                    to="/#how-it-works"
                    className="block py-2 px-3 text-secondary hover:text-primary xl:text-[18px] lg:text-base whitespace-nowrap transition-all"
                  >
                    How It Works
                  </Link>
                </li>
                <li className="xl:px-3 lg:px-0">
                  <Link
                    to="/#about-us"
                    className="block py-2 px-3 text-secondary hover:text-primary xl:text-[18px] lg:text-base whitespace-nowrap transition-all"
                  >
                    About Us
                  </Link>
                </li>
                <li className="xl:px-3 lg:px-0">
                  <Link
                    to="/#screenshots"
                    className="block py-2 px-3 text-secondary hover:text-primary xl:text-[18px] lg:text-base whitespace-nowrap transition-all"
                  >
                    Screenshorts
                  </Link>
                </li>
                <li className="pl-3">
                  <a
                    onClick={() => setIsModalOpen(true)}
                    href="#"
                    className="py-2 text-white transition-all  filled-button px-3 min-w-[200px] flex-center"
                  >
                    Agent Registration
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={`${mobileMenu ? "lg:relative flex flex-col absolute " : "hidden"
                }   left-0 top-20 w-full  lg:hidden items-center justify-center mobile-menu mt-3 `}
            >
              <ul
                className={`font-medium py-5 items-center  p-4 w-full mt-4  rounded-lg bg-gray-50 md:mt-0 md:bg-white `}
              >
                <li className="px-3">
                  <Link
                    to={"/"}
                    className="block py-2 px-3 text-secondary hover:text-primary md:text-[18px] text-base transition-all"
                  >
                    Home
                  </Link>
                </li>
                <li className="px-3">
                  <Link
                    to="/#how-it-works"
                    className="block py-2 px-3 text-secondary hover:text-primary md:text-[18px] text-base transition-all"
                  >
                    How It Works
                  </Link>
                </li>
                <li className="px-3">
                  <Link
                    to="/#about-us"
                    className="block py-2 px-3 text-secondary hover:text-primary md:text-[18px] text-base transition-all"
                  >
                    About Us
                  </Link>
                </li>
                <li className="px-3">
                  <Link
                    to="/#screenshots"
                    className="block py-2 px-3 text-secondary hover:text-primary md:text-[18px] text-base transition-all"
                  >
                    Screenshorts
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        setIsModalOpen={setIsModalOpen}
        isOpen={isModalOpen}
        onClose={() => handleClose()}
      />
    </>
  );
};

export default Header;

const Modal = React.memo(({ setIsModalOpen, isOpen }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    hear_us_from: "",
  });
  const [phone, setPhone] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleValidation = () => {
    let formValid = true;
    let errors = {};

    if (!formData.firstName) {
      errors.firstName = "First Name is required!";
      formValid = false;
    }
    if (!formData.lastName) {
      errors.firstName = "First Name is required!";
      formValid = false;
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required!";
      formValid = false;
    }
    if (!formData.password) {
      errors.password = "Password is required!";
      formValid = false;
    }
    if (!formData.email) {
      errors.email = "Email is required!";
      formValid = false;
    } else if (!validateEmail(formData.email)) {
      errors.email = "Email is invalid!";
      formValid = false;
    }
    if (!formData.hear_us_from) {
      errors.hear_us_from = "Please select an option!";
      formValid = false;
    }

    if (!phone) {
      errors.mobile = "Mobile is invalid!";
      formValid = false;
    } else if (!isPhoneValid(phone.full)) {
      errors.mobile = "Invalid Mobile Number!";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0 || formValid;
  };

  const onClose = () => {
    setIsModalOpen(false);
    setErrors({});
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      hear_us_from: "",
    });
    setPhone({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const req = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        country_phone_code: phone.phoneCode,
        hear_us_from: formData.hear_us_from,
        mobile: phone.full,
        country_code: "+" + phone.countryCode,
        is_real_estate_egent: true,
        password: formData.password,
      };
      setLoading(true);

      try {
        const response = await fetch(
          "https://api.scout-heim.vytech.co/auth/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(req),
          }
        );
        const data = await response.json();
        if (parseInt(data.status) === 200) {
          console.log("Registration successful");
          toast.success(
            "Thank you for registering with Scout Heim Agent. You can now download our app from the App Store or Play Store."
          );
          setLoading(false);
          onClose();
        } else {
          toast.error(data.message);
          setLoading(false);
          console.error("Registration failed:", data.message);
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };
  console.log({ phone });

  const handlePhoneChange = (value, data) => {
    const { inputValue, country } = data;
    let countryCode = "";
    let phoneNumber = "";
    const match = inputValue && inputValue.match(/^\+\d+/);
    countryCode = match ? match[0] : "";
    phoneNumber =
      inputValue && inputValue.slice(countryCode.length).trimStart();
    console.log({ phoneNumber, value });
    setPhone({
      countryCode: country.iso2,
      phoneCode: country.dialCode,
      phoneNumber: phoneNumber,
      full: value,
    });
  };

  return (
    <div
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed inset-0 flex items-center justify-center z-50 w-full h-[calc(100%-1rem)] max-h-full"
    >
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-75"
        onClick={onClose}
      ></div>
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">
              Agent Registration
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  hover:text-white"
              onClick={onClose}
              data-modal-hide="default-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className=" grid grid-cols-1 gap-x-4 w-full place-items-center md:grid-cols-2 p-4 md:p-5  gap-y-3">
              <div className="w-full">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  placeholder="Enter First Name"
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="mt-1 input"
                />
                {errors.firstName && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="input mt-1"
                />
                {errors.lastName && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.lastName}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input mt-1"
                />{" "}
                {errors.email && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile
                </label>
                <PhoneInput
                  value={phone.full}
                  forceDialCode={true}
                  disableFormatting={true}
                  placeholder="Enter Phone Number"
                  defaultCountry="us"
                  onChange={handlePhoneChange}
                />{" "}
                {errors.mobile && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.mobile}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  placeholder="Enter Password"
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="input mt-1"
                />{" "}
                {errors.password && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.password}
                  </p>
                )}
              </div>

              <div className="w-full">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                >
                  Where did you hear from us?
                </label>
                <select
                  name="hear_us_from"
                  id="role"
                  value={formData.hear_us_from}
                  onChange={handleChange}
                  className="input mt-1"
                >
                  <option value="" disabled>
                    Please select an option
                  </option>
                  <option value="Search Engine(Google,yahoo,etc.)">
                    Search Engine(Google,yahoo,etc.)
                  </option>
                  <option value="Recommended by friend or colleague">
                    Recommended by friend or colleague
                  </option>
                  <option value="Social media">Social media</option>
                  <option value="Blog or publication">
                    Blog or publication
                  </option>
                  <option value="Other">Other</option>
                </select>
                {errors.hear_us_from && (
                  <p className="text-red-500 pt-2 pl-1 font-medium text-sm">
                    {errors.hear_us_from}
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center p-4 md:p-5  rounded-b">
              {loading ? (
                <button
                  disabled
                  type="button"
                  className="text-white  bg-primary hover:bg-primary/90 ring-0 outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="text-white bg-primary hover:bg-primary/90 focus:bg-primary/90 active:bg-primary/80 ring-0 outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Register
                </button>
              )}

              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
