import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import slidericon from "../assets/img/slidericon.svg";
// import propertyvisit from "../assets/img/yellow-slider-icon.svg";
// import redslidericon from "../assets/img/slider-user-icon.svg";
// import visitnoti from "../assets/img/visitnoti.svg";
// import agentteam from "../assets/img/agentteam.svg";
import appios from "../assets/img/appios.svg";
import datasecure from "../assets/img/datasecure.svg";
import gps from "../assets/img/gps.svg";
import advance from "../assets/img/advance.svg";
import property from "../assets/img/property.svg";
import attraction from "../assets/img/attraction.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "../assets/scss/_slider.scss";

// import required modules
import { EffectCards, Navigation } from "swiper/modules";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiBarChartLine,
  RiBrushLine,
  RiCalendar2Line,
  RiCodeSSlashLine,
  RiCustomerService2Line,
  RiHandHeartLine,
  RiHomeSmileLine,
  RiLock2Line,
  RiUserSettingsLine,
} from "react-icons/ri";

export default function Slider() {
  const windowWidth = window.innerWidth > 1000;

  const slidesData = [
    {
      img: <RiHomeSmileLine size={30} />,
      title: "Property Showcase",
      description:
        "Scout Heim Agent allows you to showcase properties in their best light, with the ability to add detailed descriptions, high-resolution images, and even virtual tours. This helps potential buyers get a comprehensive view of the property before scheduling a viewing.",
    },
    {
      img: <RiCustomerService2Line size={30} />,
      title: "Client Engagement",
      description:
        "Engage with potential buyers directly through the app. You can chat with them, answer their questions, and schedule property viewings, making the process more convenient for both parties.",
    },
    {
      img: <RiCalendar2Line size={30} />,
      title: "Open House Management",
      description:
        "Manage open houses with ease. You can schedule open houses, register visitors, and follow up with them after the event, all within the app. This streamlines the process and helps you make the most of your open house events.",
    },
    {
      // img: appios,
      img: <RiUserSettingsLine size={30} />,
      title: "Intuitive Interface",
      description:
        "The app features an intuitive interface that is easy to navigate, allowing you to access key features and information quickly. Whether you're adding a new property listing or following up with a client, the app makes it simple and straightforward.",
    },
    {
      // img: datasecure,
      img: <RiBarChartLine size={30} />,
      title: "Property Insights",
      description:
        "Gain valuable insights into your property listings. Scout Heim Agent provides detailed analytics on property views, inquiries, and other metrics, helping you understand what's working and how you can improve your listings.",
    },
    {
      // img: gps,
      img: <RiBrushLine size={30} />,
      title: "Customization",
      description:
        "Customize your property listings and client interactions to match your branding and style. This helps you create a cohesive brand image and stand out in a competitive market.",
    },
    {
      // img: advance,
      img: <RiLock2Line size={30} />,
      title: "Security",
      description:
        "Scout Heim Agent prioritizes the security of your client data and property information. The app is equipped with built-in security features to ensure that your data remains safe and secure.",
    },
    {
      // img: property,
      img: <RiCodeSSlashLine size={30} />,
      title: "Integration",
      description:
        "Seamlessly integrate Scout Heim Agent with other real estate tools and platforms for a more connected workflow. Whether you're using CRM software or marketing tools, integration with Scout Heim Agent makes your job easier.",
    },
    {
      img: <RiHandHeartLine size={30} />,
      title: "Support",
      description:
        "Get the support you need to succeed. Scout Heim Agent offers dedicated support and training resources to help you make the most of the app and stay ahead in the real estate market.",
    },
  ];

  return (
    <div className="relative mySwiper-container mb-10 py-10">
      <div className="w-[70%] mx-auto">
        <h1 className="text-center page-title md:pb-4 pb-2">Features</h1>
        <p className="text-center  page-desc  md:pb-5 pb-3">
          Discover our innovative features designed to enhance your experience.
        </p>
      </div>

      {/* For Desktop Slider */}

      <Swiper
        effect={"cards"}
        navigation={{
          // Add navigation prop here
          prevEl: ".swiper-button-new-prev",
          nextEl: ".swiper-button-new-next",
        }}
        grabCursor={true}
        modules={[EffectCards, Navigation]}
        className="mySwiper hidden lg:block lg:w-[55%]  mb-[200px] lg:mb-20  w-11/12"
      >
        {slidesData.map((slider, index) => (
          <SwiperSlide key={index}>
            <div className="px-10">
              <div className="flex items-center justify-center pb-7">
                {slider.img}
                <p className="pl-4 text-[30px]">{slider.title}</p>
              </div>
              <p className="text-[18px] font-normal">{slider.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* For Desktop Slider */}

      {/* For Mobile Slider */}
      <Swiper
        navigation={{
          // Add navigation prop here
          prevEl: ".swiper-button-new-prev",
          nextEl: ".swiper-button-new-next",
        }}
        effect={"cards"}
        grabCursor={true}
        modules={[Navigation]}
        className="mySwiper flex lg:hidden lg:w-[55%]  mb-[50px]  w-11/12"
      >
        {slidesData.map((slider, index) => (
          <SwiperSlide key={index}>
            <div className="px-10">
              <div className="flex justify-center pb-7">
                <img src={slider.img} alt="" />
                <p className="pl-4 text-[20px]">{slider.title}</p>
              </div>
              <p className="text-[15px] font-normal">{slider.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-new-prev">
        <RiArrowLeftSLine />
      </div>
      <div className="swiper-button-new-next">
        <RiArrowRightSLine />
      </div>
      {/* For Mobile Slider */}
    </div>
  );
}
